<template>
    <div class="bg-white border border-beige-dark overflow-hidden flex rounded-lg">
        
        
        <div class="flex-none bg-beige-dark w-24 flex items-center justify-center text-4xl relative">
            <img 
                v-if="department.icon"
                :src="`/icons/${department.icon}`"
                class="w-full h-full p-4 opacity-75"
            />
            <!-- <div 
                v-if="department.createdBy && department.createdBy.accountId && department.createdBy.accountId != this.accountId"
                class="">
                not be you!
            </div> -->
        </div>
        <div class="flex-grow p-8">
            <h3 class="text-lg font-bold leading-tight">
				<span v-if="department.deletedAt" class="bg-red-600 text-white px-1 font-normal mr-1 rounded-sm">Slettet</span>
				{{department.name}}
			</h3>
            <div class="flex space-x-4">
                
                <div class="flex">
                    <div class="bg-beige px-2 rounded">
                        {{department.products && department.products.length ? department.products.length : 0}}
                    </div>
                    <div class="ml-2">
                        {{!department.products || department.products.length != 1 ? 'produkter' : 'produkt'}}
                    </div>
                </div>
                <div class="flex">
                    <div class="bg-beige px-2 rounded">
                        {{department.tasks && department.tasks.length ? department.tasks.length : 0}}
                    </div>
                    <div class="ml-2">
                        {{!department.tasks || department.tasks.length != 1 ? 'oppgaver' : 'oppgave'}}
                    </div>
                </div>
            </div>
			<!-- <pre>{{department.departmentId}}</pre> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        department: {
            type: Object,
            required: true,
        }
    },
    
    data() {
        return {
            loading: null,
        }
    },
}
</script>

<style lang="scss">
</style>